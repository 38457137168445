import { csrfToken } from '../../graphql/apollo.client'
import { getApiPath } from '../../httpClient'

export interface CloudflareImagesUploadResult {
  result: {
    id: string
    filename: string
    uploaded: string
    requireSignedURLs: boolean
    variants: string[]
  }
  result_info: unknown
  success: boolean
  errors: string[]
  messages: string[]
}

export type MediaUploadResult =
  | {
      ok: CloudflareImagesUploadResult
    }
  | {
      error: {
        code: MediaUploadErrorCode
        message?: string
        cloudflareImagesResult?: CloudflareImagesUploadResult
      }
    }

export enum MediaUploadErrorCode {
  UNAUTHORIZED = 'UNAUTHORIZED',
  FILE_TOO_LARGE = 'FILE_TOO_LARGE',
  MISSING_FILE_IN_FORM_DATA = 'MISSING_FILE_IN_FORM_DATA',
  CLOUDFLARE_ERROR = 'CLOUDFLARE_ERROR',
}

export async function uploadMedia(file: File): Promise<MediaUploadResult> {
  const url = getApiPath(`/media/`)
  const body = new FormData()
  body.append('file', file)

  const response = await fetch(url, {
    body,
    method: 'POST',
    headers: {
      csrf: csrfToken,
    },
  })
  const result: MediaUploadResult = await response.json()
  return result
}

export function getCdnUrl(cloudflareUrl: string): string {
  const url = new URL(cloudflareUrl)

  return `https://yupty.live/cdn-cgi/imagedelivery${url.pathname}`
}

export async function deleteMedia(id: string): Promise<MediaUploadResult> {
  const url = getApiPath(`/media/${id}`)

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      csrf: csrfToken,
    },
  })
  const result: MediaUploadResult = await response.json()
  return result
}
