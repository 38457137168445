import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'

export const container: StyleWithOptions<boolean | undefined> =
  (disabled) =>
  ({ size, borderColor, backgroundColor, fontColor, radius }) => ({
    padding: size.M,
    backgroundColor: disabled
      ? backgroundColor.disabled
      : backgroundColor.primary,
    color: disabled ? fontColor.disabled : fontColor.plain.primary,
    border: '2px dashed',
    borderColor: disabled ? backgroundColor.disabled : borderColor.secondary,
    borderRadius: radius.primary,
    textAlign: 'center',
    display: 'flex',
    gap: size.XS,
    justifyContent: 'center',
    flexDirection: 'column',
    userSelect: 'none',
  })

export const previewContainer: Style = css({
  display: 'flex',
  gap: mTheme.size.S,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
})

export const preview: Style = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifySelf: 'center',
  maxWidth: '30vw',
  padding: mTheme.size.XS,
  border: '2px dashed',
  borderColor: mTheme.borderColor.secondary,
  borderRadius: mTheme.radius.primary,
  textAlign: 'center',
  overflow: 'auto',
})

export const icon: Style = ({ size }) => ({
  marginRight: size.XXS,
})

export const error: Style = css({
  color: mTheme.color.alert,
})

export const sizeLimit: Style = css({
  color: mTheme.fontColor.plain.tertiary,
})
