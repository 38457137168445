import React, { useEffect, useState } from 'react'
import * as Dropzone from 'react-dropzone'

import Button from '../Button'

import * as styles from './PictureDropzone.styles'
import { MediaUploadResult, uploadMedia } from './mediaUploads'
import { formatBytes } from '../../utilities/formatBytes'

interface PictureDropzoneProps extends Dropzone.DropzoneProps {
  onUploaded: (result: MediaUploadResult) => void
  onClear: () => void
  dragActiveCopy?: string
  dragInactiveCopy?: string
  onUploadingStatusChanged?: (isUploading: boolean) => void
}

const PictureDropzone: React.FC<PictureDropzoneProps> = ({
  onUploaded,
  onDropAccepted,
  onClear,
  dragActiveCopy = 'Drop the image here...',
  dragInactiveCopy = 'Select an image',
  onUploadingStatusChanged,
  disabled,
}) => {
  const maxSize = 10000000 // tenMegabytes-ish
  const maxSizeFormatted = formatBytes(maxSize)
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    Dropzone.useDropzone({
      disabled,
      onDropAccepted,
      accept: { 'image/jpeg': [], 'image/png': [] },
      maxSize,
      onDropRejected: ([rejection]) => {
        const error = rejection.errors[0]
        if (error.code === 'file-too-large') {
          setError(`The file must be smaller than ${formatBytes(maxSize)}`)
        }
      },
    })

  const [preview, setPreview] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  // const [imageId, setImageId] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setError('')

      setPreview(URL.createObjectURL(acceptedFiles[0]))
      setIsUploading(true)
      if (onUploadingStatusChanged) {
        onUploadingStatusChanged(true)
      }

      uploadMedia(acceptedFiles[0]).then((result) => {
        setIsUploading(false)
        if (onUploadingStatusChanged) {
          onUploadingStatusChanged(false)
        }
        if ('ok' in result) {
          // setImageId(result.ok.result.id)
        }

        if ('error' in result) {
          setError(String(result.error.message))
        }

        onUploaded(result)
      })
    }

    return () => {
      if (preview) {
        URL.revokeObjectURL(preview)
      }
    }
  }, [acceptedFiles])

  if (preview) {
    return (
      <div css={styles.previewContainer}>
        <img src={preview} alt="Picture preview" css={styles.preview} />
        {isUploading && <span>Uploading...</span>}

        {!isUploading && (
          <Button
            onClick={(e) => {
              e.stopPropagation()
              setPreview('')
              onClear()
              // deleteMedia(imageId)
            }}
            fullWidth
            plain
          >
            <i css={styles.icon} className="fa fa-times close-button" />
            Remove image
          </Button>
        )}
      </div>
    )
  }

  const text = isDragActive ? dragActiveCopy : dragInactiveCopy

  return (
    <div css={styles.container(disabled)} {...getRootProps()}>
      <label className="input-label">
        <i css={styles.icon} className="fas fa-camera" />
        {text}
      </label>

      <small css={styles.sizeLimit}>Up to {maxSizeFormatted}</small>

      {error && <span css={styles.error}>{error}</span>}

      <input {...getInputProps()} />
    </div>
  )
}

export default PictureDropzone
